import * as React from "react";

import Header from "../components/Navbar/Header/Header";
import Footer from "../components/Footer/Footer";

import "../css/pages/rd.scss";

// Kutatás és fejlesztés oldal
const RdPage = () => {
  return (
    <>
      <Header />
      <main className="rd-page">k&f</main>
      <Footer></Footer>
    </>
  );
};

export default RdPage;
